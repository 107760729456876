<template lang="pug">
v-col
  v-slide-group(:id="uid" show-arrows)
    v-slide-item(v-for="(image, i) in photos" :key="image.uuid")
      //- div(class="image-coord-container mr-2" v-for="(image, i) in photos" :key="image.id")
      div(class="image-coord-container mr-2")
        img(class="image-coord" :src="getResource(image)" @click="openCarousel(photos, i)")
        v-btn(v-if="!isCustomer" fab rounded x-small :disabled="image.loading" @click.stop="removePhoto(image)")
          v-icon mdi-delete
  v-dialog(v-model="dialogPhoto" width="unset" content-class="image-coordinator-window")
    v-carousel(hide-delimiters v-model="current_carousel" :continuous="false" height="auto")
      v-carousel-item(class="text-center" v-for="(item, i) in carousel" :key="i")
        img(:src="getResource(item)")
</template>


<script>
import { config } from '@/_helpers'
import { reportService, motivationalReportService } from '@/_services'
import { mapGetters } from "vuex";

export default {
  props: ['uuid', 'photos', 'item', 'id', 'type' , 'reportType','photoType'],
  
  data: () => ({
      carousel: [],
      current_carousel: 1,
      dialogPhoto: false,
  }),

  computed: {
    ...mapGetters("authentication", ["checkRole"]),
    isCustomer() {
      return this.checkRole(0)
    },
    uid() {
      return 'slider_' + this.id
    },
  },

  mounted() {
    if(this.reportType != 'motivational'){
      let el = document.getElementById(this.uid)
      let list = el.getElementsByClassName('v-slide-group__prev')
      list[0].classList.add('v-slide-view')
      list[0].classList.remove('v-slide-group__prev--disabled')
      list = el.getElementsByClassName('v-slide-group__next')
      list[0].classList.add('v-slide-view')
      list[0].classList.remove('v-slide-group__next--disabled')
      console.log(list);
      let parent = el.closest('#image-coord-container');
      if (parent != null) {
        el.style.maxWidth = (el.closest('.container').clientWidth - 30) + 'px'
      }
    }
    
  },

  methods: {
    openPhoto(type) {
      this.dialogPhoto = type
    },

    getResource(image) {
      let photo = this.photos.find(photo => photo.uuid == image.uuid)
      let createDate
      if(photo){
        createDate = photo.createDate.split("-")
      }
      return photo ? config.image(photo, createDate[0], createDate[1], createDate[2]) : ''
    },

    openCarousel(photos, idx) {
      this.current_carousel = idx
      this.carousel = photos
      this.dialogPhoto = true
      
    },

    removePhoto(image) {
      this.$set(image, 'loading', true)
      if(this.reportType == "motivational"){
        this.$emit('func', motivationalReportService.deleteMotivationalPhoto, this.item, this.type, this.id, image.uuid, (d) => {
          this.item.photos = this.item.photos.filter(im => im.uuid != image.uuid);
        })
      } else {
        this.$emit('func', this.type == 0 ? reportService.deletePhoto : reportService.deleteDataPhoto, image, this.type, this.id, image.uuid, (d) => {
          this.item.photos = this.item.photos.filter(im => im.uuid != image.uuid);
        })
      }

    },
  }
}
</script>

<style>
.text-nowrap {
  white-space: nowrap !important;
}
.v-btn .v-input__prepend-outer {
  margin-top: 4px;
  margin-right: 0;
}
.image-coord-container .v-btn {
  position: absolute;
  top: 4px;
  right: 4px;
}
.image-coord {
  height: 100px;
}
.image-coord-container {
  display: inline-block;
  position: relative;
  max-width: 200px;
  overflow: hidden;
}
.image-coordinator {
  height: 500px;
  max-width: 100%
}
.v-slide-view {
  display: inherit !important
}
.image-coordinator-window {
  height: 90% !important;
}
.image-coordinator-window .v-window, .image-coordinator-window .v-window__container, .image-coordinator-window .v-window-item, .image-coordinator-window .v-window-item div {
  height: 100% !important;
  width: 100% !important;
}
.image-coordinator-window img {
  max-width: 100%;
  max-height: 100%;
}
.image-coordinator-window .v-window__prev {
  position: fixed;
  left: 20px;
}
.image-coordinator-window .v-window__next {
  position: fixed;
  right: 20px;
}
</style>