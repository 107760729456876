<template lang="pug">
v-btn(class="mr-3" rounded small :loading="loading" :disabled="loading")
  v-file-input(v-model="tempPhotos" label="File input" hide-input :multiple="photoType == 'omp' ? false : true" filled prepend-icon="mdi-image-plus" @change="addPhoto")
</template>


<script>
import { reportService, fileService, motivationalReportService } from '@/_services'

export default {
  props: ['item', 'type', 'reportType', 'photoType'],
  data: () => ({
    tempPhotos: [],
    dataPhotos: [],
    loading: false
  }),

  methods: {

    addPhoto() {
      this.dataPhotos = []
      if (this.tempPhotos.length > 0) {
        this.upload()
      }
    },

    upload() {
      this.loading = true
      let formData = new FormData()
      console.log((this.reportType == "motivational" && this.photoType != "omp"), this.reportType != "motivational");
      
      formData.append("files[]", this.tempPhotos.pop());
      fileService.uploadFiles(formData, this.reportType == "motivational" ? true : false, (this.reportType == "motivational" && this.photoType != "omp") || this.reportType != "motivational" ? true : false ).then(data => {
        data.map(photo => {
          if(photo.alreadyLoaded){
            window.alert("Данное фото уже загружено")
          }
        })
        this.dataPhotos = this.dataPhotos.concat(data)
        if (this.tempPhotos.length != 0) {
          this.upload()
        } else{
          if(this.reportType == "motivational"){
            if(this.photoType == "omp"){
              let ompPhoto = this.item.photos.filter(x => !x.dmp)[0]
              if(ompPhoto){
                motivationalReportService.deleteMotivationalPhoto(this.item.id, ompPhoto.uuid).then(() => {
                this.item.photos = this.item.photos.filter(im => im.uuid != ompPhoto.uuid);
                this.$emit('func', motivationalReportService.addPhotoToReport, this.item, this.type, this.item.id, this.dataPhotos, (d) => {
                  this.item.photos = [].concat(d)
                  this.loading = false
                })
              })
              } else {
                this.$emit('func', motivationalReportService.addPhotoToReport, this.item, this.type, this.item.id, this.dataPhotos, (d) => {
                  this.item.photos = [].concat(d)
                  this.loading = false
                })
              }
            } else {
              this.dataPhotos.map(el => {
                el.dmp = true
              })
              this.$emit('func', motivationalReportService.addPhotoToReport, this.item, this.type, this.item.id, this.dataPhotos, (d) => {
                this.item.photos = [].concat(d)
                this.loading = false
              })
            }

          } else {
            this.$emit('func', this.type == 0 ? reportService.savePhoto : reportService.saveDataPhoto, this.item, this.type, this.item.id, this.dataPhotos, (d) => {
              this.item.photos = [].concat(d)
              this.loading = false
            })
          }
        }
      }).catch(error => console.log(error))
      // .finally(() => this.loading = false)
    },

    changePhotoType(){
      console.log(132);
    }

  }
}
</script>

<style>
    .text-nowrap {
      white-space: nowrap !important;
    }
    .v-btn .v-input__prepend-outer {
      margin-top: 4px;
      margin-right: 0;
    }
</style>